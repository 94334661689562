<template>
  <v-row justify="center">
    <v-dialog
      scrollable
      v-model="show"
      max-width="700px"
      @click:outside="init()"
      @keydown="$event.key === 'Escape' ? init() : ''"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
          :class="type === 2 || type === 3?'justify-space-between':''"
        >
          <span class="text-h5">{{ title }}</span>
          <div class="text-box" v-if="type === 2 || type === 3">
            <v-text-field
              class="mt-2"
              label="권한프리셋 이름"
              outlined
              :rules="rules"
              :value="name"
              @input="$emit('update:name', $event)"
              dense
            ></v-text-field>
          </div>
          <div class="select-users" v-if="type === 5">
            <v-chip
              v-for="(item, index) in selectUsers"
              small
              :key="index"
              color="accent"
              class="mr-1"
            >{{ item.name }}({{ item.id }})</v-chip>
          </div>
          <v-chip
            v-else-if="type !== 4 && type !== 2 && type !== 3"
            class="ml-1 mb-1"
          >
            {{ name }}
          </v-chip>
        </v-card-title>
        <div class="load-wrap" v-if="presets.length > 0">
          <p class="load-text pl-2 mb-3">
            {{ type === 4 ? '프리셋 선택' : '프리셋 불러오기' }}
          </p>
          <v-slide-group
            show-arrows
          >
            <v-slide-item
              v-for="(item, index) in presets"
              :key="index"
            >
              <v-btn
                class="mx-1"
                depressed
                :color="type === 4 && selectIndex === index ? 'primary' : ''"
                small
                @click="loadPreset(item.permission, index, item.name)"
              >
                {{ item.name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </div>
        <v-card-text class="mt-4" v-if="type !== 4">
          <v-expansion-panels
            v-model="panel"
            multiple
            outlined
            flat
            tile
            focusable
          >
            <v-expansion-panel
              class="panel-list"
              v-for="(category, index) in permission"
              v-bind:key="index"
              active-class="on"
              :class="Object.keys(category.menu).length === 0?'none':''"
            >
              <v-expansion-panel-header>
                <p class="panel-header">
                  {{ category.name }}
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="checkbox-wrap">
                  <div
                    v-for="(menu, index) in category.menu"
                    v-bind:key="index"
                    class="menu-wrap"
                    :class="menu.func?'full-wrap':''"
                  >
                    <v-checkbox
                      class="mr-10"
                      v-model="menu.bool"
                      :label="menu.name"
                      dense
                    ></v-checkbox>
                    <div
                      class="func-wrap"
                      v-if="menu.func"
                    >
                      <v-checkbox
                        class="mr-4"
                        v-for="(func, i) in menu.func"
                        v-bind:key="i"
                        v-model="func.bool"
                        :label="func.name"
                        dense
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <div class="empty-box" v-else-if="presets.length <= 0">
          저장된 프리셋이 없습니다.
        </div>
        <div class="empty-box" v-else-if="selectIndex === null">
          프리셋을 선택해주세요.
        </div>
        <v-card-text class="mt-4" v-else>
          <v-expansion-panels
            v-model="panel"
            multiple
            outlined
            flat
            tile
            focusable
          >
            <v-expansion-panel
              class="panel-list"
              v-for="(category, index) in permissionDup"
              v-bind:key="index"
              active-class="on"
              v-show="checkCategory(category)"
            >
              <v-expansion-panel-header>
                <p class="panel-header">
                  {{ category.name }}
                </p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="checkbox-wrap">
                  <div
                    v-for="(menu, index) in category.menu"
                    v-bind:key="index"
                    class="menu-wrap"
                    :class="menu.func?'full-wrap':''"
                  >
                    <v-checkbox
                      class="mr-10"
                      v-model="menu.bool"
                      v-show="menu.bool"
                      readonly
                      :label="menu.name"
                      dense
                    ></v-checkbox>
                    <div
                      v-if="
                        menu.func
                        && Object.values(menu.func).filter(r => r.bool).length > 0
                      "
                    >
                      <v-checkbox
                        class="mr-4"
                        v-for="(func, i) in menu.func"
                        v-bind:key="i"
                        v-model="func.bool"
                        :label="func.name"
                        v-show="func.bool"
                        readonly
                        dense
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-6">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="type === 4 && selectIndex == null"
            color="primary"
            depressed
            @click="updateAuth()"
          >
            {{ btnText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import comm from '@/util/comm';

export default {
  name: 'DlgUserAuth',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    permission: {
      type: Object,
      required: true,
    },
    permissionUpdate: {
      type: Object,
    },
    type: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
    },
    uk: {
      type: String,
    },
    presets: {
      type: Array,
    },
    func: {
      type: Function,
      default: () => {},
    },
    selectUsers: {
      type: Array,
    },
  },
  data: () => ({
    comm,
    boards: [],
    panel: [],
    rules: [],
    selectIndex: null,
    selectName: null,
    permissionDup: null,
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
    }),
    title() {
      let text = '';
      if (this.type === 3) {
        text = '권한프리셋수정';
      } else if (this.type === 2) {
        text = '권한프리셋등록';
      } else if (this.type === 1 || this.type === 5) {
        text = '권한수정';
      } else if (this.type === 0) {
        text = '권한등록';
      } else if (this.type === 4) {
        text = '권한설정';
      }
      return text;
    },
    btnText() {
      let text = '';
      if (this.type === 2 || this.type === 0) {
        text = '등록';
      } else if (this.type === 3 || this.type === 1 || this.type === 5) {
        text = '수정';
      } else if (this.type === 4) {
        text = '설정';
      }
      return text;
    },
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    loadPreset(permission, index, name) {
      this.$socket.emit('permissions.preset.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          const defaultPreset = resp.items.filter((r) => r.no === 1)[0].permission;
          const upToDatePreset = comm.makeUserPermissionUpToDate(
            defaultPreset,
            { ...permission },
          );
          if (this.type !== 4) {
            this.$emit('update:permission', upToDatePreset);
            this.alert(['success', '권한프리셋을 불러왔습니다.']);
          } else {
            this.permissionDup = upToDatePreset;
            this.selectName = name;
            this.selectIndex = index;
          }
          for (let i = 0; i < Object.keys(this.permission).length; i += 1) {
            this.panel.push(i);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`], false);
          console.error(resp);
        }
      });
    },
    init() {
      this.$emit('update:show', false);
      this.rules = [];
      this.selectName = null;
      this.selectIndex = null;
    },
    updateAuth() {
      let req = {};
      if (this.type === 0) {
        req = {};
      } else if (this.type === 1) {
        req = {};
      } else if (this.type === 2) {
        if (this.rules.length === 0) {
          if (this.name === null || this.name.trim().length === 0) {
            this.rules = ['프리셋이름을 입력해주세요.'];
          } else {
            req = {
              name: this.name.trim(),
              permission: this.permission,
            };
            this.$socket.emit('permissions.preset.add', req, (resp) => {
              if (resp.result === 'success') {
                this.$emit('update:show', false);
                this.alert(['success', '등록되었습니다.']);
                this.func();
              } else {
                this.$emit('update:show', false);
                this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              }
            });
          }
        }
      } else if (this.type === 3) {
        if (this.rules.length === 0) {
          req = {
            no: Number(this.uk),
            name: this.name.trim(),
            permission: this.permission,
          };
          this.$socket.emit('permissions.preset.update', req, (resp) => {
            if (resp.result === 'success') {
              this.$emit('update:show', false);
              this.alert(['success', '수정되었습니다.']);
              this.func();
            } else {
              this.$emit('update:show', false);
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            }
          });
        }
      } else if (this.type === 5) {
        const usersArr = [];
        this.selectUsers.forEach((r) => {
          usersArr.push({
            id: r.id,
            permission: this.permission,
          });
        });
        this.func(usersArr, '권한이 수정되었습니다.');
        this.init();
      } else if (this.type === 4) {
        this.func(this.permissionDup, this.selectName);
        this.init();
      }
    },
    getBoardList() {
      this.$socket.emit('board.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [
          {
            condition: 'ne',
            column: 'no',
            value: 0,
          },
        ],
      }, (resp) => {
        if (resp.result === 'success') {
          this.boards = resp.items;
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    permissionAddBoard() {
      if (this.boards.length > 0) {
        this.boards.forEach((r) => {
          const isPermission = Object.keys(this.permission.boards.menu).includes(String(r.no));
          if (isPermission === true) {
            this.permission.boards.menu[String(r.no)].name = r.name;
          } else {
            this.permission.boards.menu[String(r.no)] = {
              name: r.name,
              bool: false,
            };
          }
        });
        Object.keys(this.permission.boards.menu).forEach((r) => {
          const isBoard = this.boards.filter((row) => String(row.no) === r).length;
          if (isBoard === 0) {
            delete this.permission.boards.menu[r];
          }
        });
      }
    },
    checkCategory(category) {
      let bool = false;
      Object.values(category.menu).forEach((menu) => {
        if (menu.bool === true) {
          bool = true;
        } else if (menu.func !== undefined) {
          Object.values(menu.func).forEach((func) => {
            if (func.bool === true) bool = true;
          });
        }
      });
      return bool;
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getBoardList();
        }
      },
    },
    permission: {
      immediate: true,
      handler() {
        this.permissionAddBoard();
      },
    },
    show: {
      handler() {
        if (this.show === true) {
          for (let i = 0; i < Object.keys(this.permission).length; i += 1) {
            this.panel.push(i);
          }
        }
      },
    },
    name: {
      handler() {
        if (this.type === 2 || this.type === 3) {
          if (this.name === null) {
            this.rules = [];
          } else if (this.name.trim().length === 0) {
            this.rules = ['프리셋이름을 입력해주세요.'];
          } else if (this.name.trim().length > 50) {
            this.rules = ['프리셋이름은 50자까지만 가능합니다.'];
          } else {
            this.rules = [];
          }
        }
      },
    },
  },
};
</script>
<style scoped lang="scss">
.select-users {
  margin-top:10px;
  padding-bottom:6px;
  padding-left: 6px;
  border-radius: 4px;
  border:1px solid #ececec;
  width: 100%;
  background-color: #fff;
}
.panel-header {
  font-size: 1.1rem;
  margin:0;
  color:#333;
}
.panel-list {
  border:1px solid #ccc;
  &+.panel-list {
    margin-top:10px;
  }
  &+.none {
    display: none;
  }

}
.load-wrap {
  border-radius: 6px;
  padding: 10px;
  margin:10px 24px;
  margin-top:0;
  border:1px solid #ccc;
  .load-text {
    font-size: .9rem;
    font-weight: 600;
  }
}
.text-box {
  margin-bottom:-12px;
  margin-left: 10px;
}
.checkbox-wrap {
  display: flex;
  flex-wrap: wrap;
  .full-wrap {
    width: 100%;
  }
  .func-wrap {
    width: fit-content;
    display: flex;
    margin-top:-4px;
    padding-top:10px;
    padding-right: 10px;
    padding-left: 20px;
    margin-bottom:15px;
    background-color: #fff;
    border:1px solid #ccc;
    border-radius: 6px;
    flex-wrap: wrap;
    margin-left: 30px;
  }
}
.empty-box {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 25px;
  font-size: 1rem;
  margin: 0px 24px;
}
</style>
